import Jquery from './jquery-3.7.1.min.js';
window.jQuery = Jquery;
window.$ = Jquery;

require('bootstrap');
import bootstrap from './bootstrap.min.bundle.js';
window.bootstrap = bootstrap;
window.moment = require('moment');

import  createPopper  from '@popperjs/core';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

window.moment = require('moment');

//import Chart from 'chart.js/auto';
const { Chart } = await import('chart.js');
import { getRelativePosition } from 'chart.js/helpers';

import DataTable from 'datatables.net-dt';
require('datatables.net-buttons-dt');
require('datatables.net-responsive-dt');
require('datatables.net-responsive');
window.DataTable = DataTable;

/*require('datatables.net-dt');

require('datatables.net-responsive-dt');
require('datatables.net-buttons/js/buttons.colVis.mjs');
require('datatables.net-buttons/js/buttons.html5.mjs');*/

